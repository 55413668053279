(function() {
  $(function() {
    $('body').on('click', '#select_all_moulds.toggle-checkbox', function() {
      return $('#moulds input[type="checkbox"]').prop('checked', this.checked);
    });
    $('body').on('click', '#select_all_aps.toggle-checkbox', function() {
      return $('#ap_tools input[type="checkbox"]').prop('checked', this.checked);
    });
    $('body').on('click', '#receive_all_tools', function() {
      var ap_tool_page, ap_tools_ids, location_id, mould_ids, mould_page, newForm, path;
      mould_ids = "?";
      mould_page = "&mould_page=" + $('#mould_page').val();
      ap_tools_ids = "?";
      ap_tool_page = "&ap_tool_page=" + $('#ap_tool_page').val();
      location_id = "&location_id=" + $('#location_id').val();
      $('#moulds input[type="checkbox"]:checked').each(function() {
        return mould_ids += "mould_ids[]=" + this.value + "&";
      });
      $('#ap_tools input[type="checkbox"]:checked').each(function() {
        return ap_tools_ids += "ap_tool_ids[]=" + this.value + "&";
      });
      if (mould_ids === '?' && ap_tools_ids === '?') {
        return $('#transfers-hidden-alert-nothing-selected').fadeIn();
      } else {
        path = '/transfers/receive';
        if (mould_ids !== '?' && ap_tools_ids !== '?') {
          mould_ids = mould_ids.slice(0, -1);
          ap_tools_ids = ap_tools_ids.slice(0, -1).substr(1);
          path = path + mould_ids + mould_page + "&" + ap_tools_ids + ap_tool_page;
        } else if (mould_ids !== '?') {
          mould_ids = mould_ids.slice(0, -1);
          path = path + mould_ids + mould_page;
        } else {
          ap_tools_ids = ap_tools_ids.slice(0, -1);
          path = path + ap_tools_ids + ap_tool_page;
        }
        path += location_id;
        newForm = $("<form method='POST', action=" + path + "></form>");
        $('body').append(newForm);
        return newForm.submit();
      }
    });
    $('body').on('click', '#receive_all_moulds', function() {
      var location_id, mould_ids, mould_page, newForm, path;
      mould_ids = "?";
      mould_page = "&mould_page=" + $('#mould_page').val();
      location_id = "&location_id=" + $('#location_id').val();
      $('#moulds input[type="checkbox"]:checked').each(function() {
        return mould_ids += "mould_ids[]=" + this.value + "&";
      });
      if (mould_ids === '?') {
        return $('#transfers-hidden-alert-nothing-selected').fadeIn();
      } else {
        mould_ids = mould_ids.slice(0, -1);
        path = '/transfers/receive' + mould_ids + mould_page + location_id;
        newForm = $("<form method='POST', action=" + path + "></form>");
        $('body').append(newForm);
        return newForm.submit();
      }
    });
    return $('body').on('click', '#receive_all_ap_tools', function() {
      var ap_tool_page, ap_tools_ids, location_id, newForm, path;
      ap_tools_ids = "?";
      ap_tool_page = "&ap_tool_page=" + $('#ap_tool_page').val();
      location_id = "&location_id=" + $('#location_id').val();
      $('#ap_tools input[type="checkbox"]:checked').each(function() {
        return ap_tools_ids += "ap_tool_ids[]=" + this.value + "&";
      });
      if (ap_tools_ids === '?') {
        return $('#transfers-hidden-alert-nothing-selected').fadeIn();
      } else {
        ap_tools_ids = ap_tools_ids.slice(0, -1);
        path = '/transfers/receive' + ap_tools_ids + ap_tool_page + location_id;
        newForm = $("<form method='POST', action=" + path + "></form>");
        $('body').append(newForm);
        return newForm.submit();
      }
    });
  });

}).call(this);
