(function() {
  $(function() {
    var locationsToggler, scrollToLastEdited;
    $('body').on('change', '.refresh_locations', function() {
      var dropDown, target, value;
      dropDown = $(this);
      value = dropDown.val();
      target = dropDown.closest('.mould_container, .ap_tool_container').find('.drawing_owner');
      // Clear select options
      target.empty();
      return $.ajax({
        url: `/locations/${value}/refresh`,
        type: 'GET',
        data: {
          id: value
        },
        success: function(data) {
          target.append('<option value></option>');
          return data.locations.forEach(function(location) {
            return target.append('<option value=\'' + location.id + '\'>' + location.name + '</option>');
          });
        }
      });
    });
    $('body').on('change', '.user_role_selector', function() {
      return locationsToggler();
    });
    locationsToggler = function() {
      var dropDown, target, value;
      target = $('.locations_checklist');
      dropDown = $('.user_role_selector');
      value = dropDown.val();
      console.log(value);
      if (parseInt(value) === parseInt($(target).data('role-id'))) {
        return target.show();
      } else {
        return target.hide();
      }
    };
    locationsToggler();
    scrollToLastEdited = function() {
      if ($(".last-edited").length === 1) {
        return $(".last-edited")[0].scrollIntoView();
      }
    };
    return scrollToLastEdited();
  });

}).call(this);
