import "./jquery_loader";
import Popper from  'popper.js';
import rails from   "@rails/ujs"; 

import 'bootstrap';

import 'chosen-js';

import "@nathanvda/cocoon";
import 'bootstrap-datepicker';
import 'data-confirm-modal';

import './datepicker.coffee';
import './locations.coffee';
//import './bootstrap_confirm.coffee';
import './clickable_row.coffee'; 
import './search.coffee';
import './select_all.coffee';
import './upload_inputs.coffee';


jQuery(document).ready( () => {
  rails.start();
  return true;
})
