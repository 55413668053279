(function() {
  $(function() {
    $('body').on('click', '#enable_series', function() {
      var series_input;
      series_input = $('#hide_series');
      if (series_input.is(":visible")) {
        return series_input.hide();
      } else {
        return series_input.show();
      }
    });
    return $(".chosen-select").chosen({
      width: '100%'
    });
  });

}).call(this);
