(function() {
  $(function() {
    return $('body').find('.input-group.date').datepicker({
      format: 'yyyy-mm-dd',
      autoclose: true,
      weekStart: 1,
      dateFormat: 'yyyy-mm-dd',
      language: '<%= I18n.locale %>',
      todayBtn: 'linked'
    });
  });

}).call(this);
