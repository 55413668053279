(function() {
  var get_file_name, get_text_input;

  this.assign_filename = function(file_input) {
    var filename;
    filename = get_file_name(file_input);
    if (filename) {
      return get_text_input(file_input).val(filename);
    }
  };

  get_text_input = function(file_input) {
    return $(file_input).parents("span.input-group").find("input.form-control");
  };

  get_file_name = function(file_input) {
    return $(file_input).val().replace(/\\/g, '/').replace(/.*\//, '');
  };

}).call(this);
